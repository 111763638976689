const ChairishContext = window.chairisher.context;

export const countryCodes = {
    ca: 'CA',
    us: 'US',
    unknown: 'UNKNOWN',
    anonymous: 'ANONYMOUS',
};

/**
 * @returns {string} The endpoint to hit to toggle country data (states, etc)
 */
export function getCountryInfoUrl() {
    return ChairishContext.COUNTRY_INFO_URL;
}

/**
 * @returns {Array<string>} An array of alpha-2 country codes.
 */
export function getPrimaryCountries() {
    return ChairishContext.PRIMARY_COUNTRIES || [];
}

/**
 * @returns {string} Primary country code.
 */
export function getPrimaryCountry() {
    return ChairishContext.CLIENT_COUNTRY_CODE || countryCodes.unknown;
}

/**
 * @returns {Array<Object>>} An array of objects containing `display` and `value` keys
 */
export function getShippableCountryChoices() {
    return ChairishContext.SHIPPABLE_COUNTRY_CHOICES || [];
}

/**
 * @param {string} countryCode An alpha-2 country code
 * @returns {Object} An object containing the `display` and `value` key of the desired country; or an empty object when not found
 */
export function getShippableCountryByCode(countryCode) {
    return getShippableCountryChoices().find((obj) => obj.value === countryCode) || {};
}

/**
 * @returns {Array<string>} An array of alpha-2 country codes.
 */
export function getShippableCountryCodes() {
    return ChairishContext.SHIPPABLE_COUNTRY_CODES || [];
}

/**
 * @returns {boolean} Is US.
 */
export function isUS() {
    return getPrimaryCountry() === countryCodes.us;
}

/**
 * @returns {boolean} if user's primary country is a country we ship to
 */
export function isUserInCountryWeShipTo() {
    return getShippableCountryCodes().includes(getPrimaryCountry());
}

const ChairishContext = window.chairisher.context;

/*
 * @returns {Array}
 */
function getSavedShippingAddresses() {
    return ChairishContext.SAVED_SHIPPING_ADDRESSES;
}

/**
 * @param {number} accountAddressId
 * @returns {Object|undefined} A saved shipping address that matches the passed in id
 */
export function getSavedShippingAddress(accountAddressId) {
    return getSavedShippingAddresses().find((addr) => addr.id.value === accountAddressId);
}

/**
 * @returns {OfferModel}
 */
export function getOffer() {
    return ChairishContext.offer;
}

/**
 * @returns {string} The title of the product an offer is being made on
 */
export function getOfferProductTitle() {
    return ChairishContext.OFFER_PRODUCT_TITLE;
}

/**
 * @returns {string} The title to use in the order summary
 */
export function getOrderSummaryCardTitle() {
    return ChairishContext.ORDER_SUMMARY_TITLE;
}

/**
 * @returns {boolean}
 */
export function isPrivateOffer() {
    return !!ChairishContext.IS_PRIVATE_OFFER;
}

/**
 * @returns {boolean} An indication of whether this offer is based on a previous invalid offer.
 */
export function isPreviousInvalidOffer() {
    return !!ChairishContext.IS_PREVIOUS_INVALID_OFFER;
}

/**
 * @returns {boolean} Whether this offer was initiated by the seller.
 */
export function isSellerInitiatedOffer() {
    return !!ChairishContext.IS_SELLER_INITIATED_OFFER;
}

/**
 * @returns {Object} Datastructure used to create a purchase via MAO or the cart
 */
export function getPurchaseObject() {
    return ChairishContext.purchaseData;
}

/**
 * @returns {String} Placeholder value to use when an address field is redacted.
 */
export function getRedactedAddressPlaceholder() {
    return ChairishContext.REDACTED_ADDRESS_PLACEHOLDER;
}

/**
 * @returns {String} Placeholder value to use when a phone number is redacted.
 */
export function getRedactedPhonePlaceholder() {
    return ChairishContext.REDACTED_PHONE_PLACEHOLDER;
}

// TODO (CHAIR-17547) remove when test is done
/**
 * @returns {number} the step of checkout the user is on
 */
export function getFarthestEditedStep() {
    return ChairishContext.FARTHEST_EDITED_STEP;
}

// TODO (CHAIR-17547) remove when test is done
/**
 * @param {number} farthestEditedStep the step of checkout the user is on
 */
export function setFarthestEditedStep(farthestEditedStep) {
    ChairishContext.FARTHEST_EDITED_STEP = farthestEditedStep;
}

export function getMulberryLogo() {
    return ChairishContext.MULBERRY_LOGO;
}

/**
 * @returns {string} Mulberry public token
 */
export function getMulberryPublicToken() {
    return ChairishContext.MULBERRY_PUBLIC_TOKEN;
}

/**
 * @returns {string}
 */
export function getMulberryShieldLogoSrcset() {
    return ChairishContext.MULBERRY_SHIELD_LOGO_SRCSET;
}

/**
 * @returns {string} Protection plan name
 */
export function getProtectionPlanName() {
    return ChairishContext.PROTECTION_PLAN_NAME;
}

/**
 * @returns {string} URL to update protection plan
 */
export function getProtectionPlanUpdateUrl() {
    return ChairishContext.PROTECTION_PLAN_UPDATE_URL;
}

/**
 * @param {number} index
 * @returns {Object}
 */
export function getPurchaseProductAtIndex(index) {
    const purchaseProduct = ChairishContext.purchaseData.products[index];
    return {
        id: purchaseProduct.id.value,
        price: purchaseProduct.meta.your_price.current.value,
        quantity: purchaseProduct.quantity.value,
        shipping_cost: purchaseProduct.shipping_cost ? purchaseProduct.shipping_cost.value : null,
        shipping_partner_code: purchaseProduct.shipping_partner_code
            ? purchaseProduct.shipping_partner_code.value
            : null,
        taxonomy: purchaseProduct.taxonomy.value,
    };
}

/**
 * @returns {string} URL to validate/submit order.
 */
export function getPurchaseUrl() {
    return ChairishContext.PURCHASE_URL;
}

/**
 * @returns {string} The string to use in the tax exemption button
 */
export function getTaxExemptionButtonText() {
    return ChairishContext.TAX_EXEMPTION_BUTTON_TEXT;
}

/**
 * @returns {string} URL to send checkout/mao data to for validation/submission
 */
export function getValidationEndpointUrl() {
    return ChairishContext.VALIDATION_ENDPOINT_URL;
}

/**
 * @returns {boolean} True indicates the purchase is being initiated via the mini cart
 */
export function isMiniCart() {
    return !!ChairishContext.IS_MINI_CART;
}

/**
 * @returns {boolean} True indicates the purchase being made is an offer
 */
export function isOffer() {
    return !!ChairishContext.isOffer;
}

/**
 * @returns {boolean} True indicates the offer price cannot bechanged
 */
export function isOfferPriceImmutable() {
    return ChairishContext.isOfferPriceImmutable;
}

/**
 * Sets the global datastructure used to create a purchase via MAO or the cart
 *
 * @param {Object} obj The object to set as the global purchase datastructure
 */
export function setPurchaseObject(obj) {
    ChairishContext.purchaseData = obj;
}

/**
 * Returns an ID for loading the PayPal SDK.
 *
 * @returns {string}
 */
export function getPaypalRestId() {
    return ChairishContext.PAYPAL_REST_ID;
}

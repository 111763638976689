const ChairishContext = window.chairisher.context;

/**
 * @returns {string}
 */
export function getGenericPaymentError() {
    return ChairishContext.GENERIC_PAYMENT_ERROR;
}

/**
 * @returns {string} Error message indicating there was a connection error with Braintree
 */
export function getBraintreeConnectionError() {
    return ChairishContext.BRAINTREE_CONNECTION_ERROR;
}

/**
 * @returns {string} The default error to display on checkboxes
 */
export function getDefaultCheckboxError() {
    return ChairishContext.DEFAULT_CHECKBOX_ERROR;
}

/**
 * @returns {string} Generic error text to use when something really goes wrong
 */
export function getDefaultErrorText() {
    return ChairishContext.DEFAULT_ERROR_TEXT;
}

/**
 * @returns {string} Django key for all validation errors that aren't attributed to a specific form field
 */
export function getDjangoNonFieldErrorsKey() {
    return ChairishContext.DJANGO_NON_FIELD_ERRORS_KEY;
}

/**
 * @returns {string} A generic error string indicating to fix above errors in the section
 */
export function getFixAllAboveError() {
    return ChairishContext.FIX_ABOVE_ERRORS;
}

/**
 * @returns {string} Error message to render when trying to add a product when the max curation limit is hit
 */
export function getMaxCurationErrorString() {
    return ChairishContext.MAX_CURATION_ERROR_STRING;
}

/**
 * @returns {string} Error text asking the user to contact support to use when something really goes wrong
 */
export function getSupportErrorText() {
    return ChairishContext.SUPPORT_ERROR_TEXT;
}

/**
 * @returns {string} The error string to display when someone tries to perform an action without accepting the TOS
 */
export function getTosErrorString() {
    return ChairishContext.TOS_ERROR_STRING;
}

/**
 * @returns {string} A generic error string to display when submit fails
 */
export function getGenericSubmitErrorString() {
    return ChairishContext.GENERIC_SUBMIT_ERROR_STRING;
}

/**
 * @returns {string}
 */
export function getRequiredFieldErrorString() {
    return ChairishContext.REQUIRED_FIELD_ERROR_STRING;
}

/**
 * @returns {boolean} Whether or not a 404 has occurred.
 */
export function is404() {
    return ChairishContext.IS_404 || false;
}

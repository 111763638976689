const ChairishContext = window.chairisher.context;

/**
 * @returns {string}
 */
export function getFacebookAppId() {
    return ChairishContext.FACEBOOK_APP_ID;
}

/**
 * @returns {string}
 */
export function getFacebookGraphApiVersion() {
    return ChairishContext.FACEBOOK_GRAPH_API_VERSION;
}

/**
 * @returns {XMLHttpRequest|undefined}
 */
export function getFacebookLoginStatusResponse() {
    return ChairishContext.FACEBOOK_LOGIN_STATUS_RESPONSE;
}

/**
 * @param {XMLHttpRequest} xhr
 */
export function setFacebookLoginStatusResponse(xhr) {
    ChairishContext.FACEBOOK_LOGIN_STATUS_RESPONSE = xhr;
}

/**
 * @returns {boolean} True indicates the Facebook JS SDK is loaded, false otherwise
 */
export function isFacebookSdkLoaded() {
    return !!ChairishContext.IS_FACEBOOK_LOADED;
}

/**
 * @param {boolean} isFacebookLoaded True indicates the Facebook JS SDK is loaded, false otherwise
 */
export function setFacebookSdkLoaded(isFacebookLoaded) {
    ChairishContext.IS_FACEBOOK_LOADED = !!isFacebookLoaded;
}
